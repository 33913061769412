<template>
  <!-- Navigation bar -->
  <div class="bdy">
    <div class="navbar_xyz" id="myNavbar">
      <router-link to="/" style="">Home</router-link>

      <div class="dropdown_xyz">
        <button class="dropbtn_xyz">
          Administration
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content">
          <router-link to="/GoverningBoard">CEDI Governing Board</router-link>
          <router-link to="/AdvisoryBoard">Expert Committee</router-link>
          <router-link to="/AdminTeam">Team</router-link>
          <!-- REMOVED
          <router-link to="/AdminMentors">Mentors</router-link>
          -->
        </div>
      </div>

      <div class="dropdown_xyz">
        <button class="dropbtn_xyz">
          Policies & Guidelines
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content" style="width:220px;">
          <a :href="policy1" target="_blank">
            CEDI Incubation Policy and Procedure 2020
          </a>
          <a :href="policy2" target="_blank">
            CEDI NITT Startup Policy for Faculty, Staff & Students
          </a>
          <a :href="policy3" target="_blank">
            NISP 2019 Policy
          </a>
          <a :href="policy4" target="_blank">
            Tamilnadu Startup Policy
          </a>
        </div>
      </div>

      <div class="dropdown_xyz">
        <button class="dropbtn_xyz">
          Facilities
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content">
          <router-link to="/IncubationServices">Incubation Services</router-link>
          <router-link to="/Labs">Facility, Labs and Infrastructure</router-link>
          <router-link to="/ContributionEcosystem">Contribution to Ecosystem</router-link>
        </div>
      </div>

      <!--
      <div class="dropdown_xyz">
        <button class="dropbtn_xyz">
          Sponsors
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content">
          <router-link to="/MeiTy">MeiTy TIDE</router-link>
          <router-link to="/SONATA">SONATA</router-link>
          <router-link to="/AsiaFoundation">ASIA Foundation</router-link>
          <router-link to="/TNSRLM">TNSRLM</router-link>
          <router-link to="/TIIC">TIIC</router-link>
          <router-link to="/EdiiTamilNadu">EDII Tamil Nadu</router-link>
          <router-link to="/EdiiAhmedabad">EDII Ahmedabad</router-link>
        </div>
      </div>
    -->
      <div class="dropdown_xyz">
        <button class="dropbtn_xyz">
          Sponsors/Partners
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content" style="width:580px;">
          <div class="row">
            <div class="col-4" style="width:200px; font-weight: bold; 
             border-right: 1px solid gray; font-size: 13px;margin-top:13px;">
              <span style="margin-left:10px;margin-top:10px;">
                Mentee Institutes
              </span>
              <router-link to="/ACET">ACET</router-link>
              <router-link to="/CCW">CCW</router-link>
              <router-link to="/CIT">CIT</router-link>
              <router-link to="/KCET">KCET</router-link>
              <router-link to="/KRIC-NEC">KRIC-NEC</router-link>
              <router-link to="/Imayam">ICE</router-link>
              <router-link to="/BonSecours">BSCW</router-link>
              <router-link to="/NehruMemorial">NMC</router-link>
            </div>
            <div class="col-4" style="width:200px; font-weight: bold; 
             border-right: 1px solid gray; font-size: 13px;margin-top:13px;">
              <span style="margin-left:10px;margin-top:10px;">
                Government Entities
              </span>
              <router-link to="/MeiTy">MeiTy TIDE</router-link>
              <!--<router-link to="/TNSRLM">TNSRLM</router-link>--> <!--Removed-->
              <router-link to="/TIIC">TIIC</router-link>
              <router-link to="/EdiiTamilNadu">EDII Tamil Nadu</router-link>
              <router-link to="/EdiiAhmedabad">EDII Ahmedabad</router-link>
            </div>
            <div class="col-4" style="width:200px; font-weight: bold; 
             border-right: 1px; font-size: 13px;margin-top:13px;">
              <span style="margin-left:10px;margin-top:10px;">
                Private Organizations
              </span>
              <router-link to="/SONATA">SONATA</router-link>
              <router-link to="/AsiaFoundation">ASIA Foundation</router-link>
              <router-link to="/TWI">TWI</router-link>
              <router-link to="/SIEMENS">SIEMENS INNOVATION GRANT PVT LTD</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown_xyz">
        <button class="dropbtn_xyz" @click="openIncubatees">
          Incubatees
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content">
          <a href="/incubatees/graduated-companies" target="_blank">Graduated</a>
          <a href="/incubatees/ongoing" target="_blank">Ongoing</a>
          <a href="/incubatees/students-faculty-start-ups" target="_blank">Students / Faculty Start-ups</a>
          <router-link to="/StartupCompendium">Startup Compendium</router-link>
        </div>
      </div>

      <div class="dropdown_xyz">
        <button class="dropbtn_xyz">
          Events
          <i class="fa fa-caret-down"></i>
        </button>
        <div class="dropdown_xyz-content">
          <router-link to="/PastEvents">Past Events</router-link>
          <router-link to="/Workshops">Workshops</router-link>
        </div>
      </div>

      <router-link to="/Application">Application</router-link>
      <!--<router-link to="/Donate">Donate</router-link>-->
      <router-link to="/Report">Report</router-link>
      <router-link to="/">Gallery</router-link>
      <!--<router-link to="/Test">Test</router-link>-->
      <router-link to="/Contact">Contact</router-link>
      <a href="javascript:void(0);" class="icon" @click="myFunction()">
        <i class="fa fa-bars"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      policy1: "/CEDI Incubation Policy and Procedure 2020.pdf",
      policy2: "/CEDI NITT Startup Policy for Faculty  Staff  Students   JUL 2021.pdf",
      policy3: "/NISP 2019 Policy.pdf",
      policy4: "/STARTUP-TN-Policy.pdf",


      isNavbarOpen: false,
      activeDropdown: null,
      isSideDropdownOpen: false,
    };
  },
  methods: {
    myFunction() {
      var x = document.getElementById('myNavbar');
      if (x.className === 'navbar_xyz') {
        x.className += ' responsive';
      } else {
        x.className = 'navbar_xyz';
      }
    },
    openIncubatees() {
      window.open("/incubatees/", '_blank');
    },


    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    toggleDropdown(dropdown) {
      if (this.activeDropdown === dropdown) {
        this.activeDropdown = null;
      } else {
        this.activeDropdown = dropdown;
      }
    },
  },
};
</script>

<style scoped>
/* Style for navigation bar */

.bdy {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar_xyz {
  overflow: hidden;
  /*background-color: white;*/
  background: #6091ba;
  /*border-radius: 10px;*/
}

.navbar_xyz a {
  float: left;
  display: block;
  /*color: #666;*/
  color: white;
  text-align: center;
  padding: 14px 12px;
  text-decoration: none;

  font-size: 13px;
  font-weight: bold;
}

.navbar_xyz a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown_xyz {
  float: left;
  overflow: hidden;
}

.dropdown_xyz .dropbtn_xyz {
  font-size: 13px;
  font-weight: bold;
  border: none;
  outline: none;
  /*color: #666;*/
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar_xyz a:hover,
.dropdown_xyz:hover .dropbtn_xyz {
  background-color: #ddd;
  color: black;
}

.dropdown_xyz-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 160px;
  z-index: 2;
  /*border-radius: 10px;*/
}

.dropdown_xyz-content a {
  float: none;
  color: #666;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown_xyz-content a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown_xyz:hover .dropdown_xyz-content {
  display: block;
}

/* Style for the menu icon */
.navbar_xyz .icon {
  display: none;
}

@media screen and (max-width: 600px) {

  .navbar_xyz a:not(:first-child),
  .dropdown_xyz .dropbtn_xyz {
    display: none;
  }

  .navbar_xyz a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .navbar_xyz.responsive {
    position: relative;
  }

  .navbar_xyz.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .navbar_xyz.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .navbar_xyz.responsive .dropdown_xyz {
    float: none;
  }

  .navbar_xyz.responsive .dropdown_xyz-content {
    position: relative;
    border-radius: 0;
  }

  .navbar_xyz.responsive .dropdown_xyz .dropbtn_xyz {
    display: block;
    width: 100%;
    text-align: left;
  }
}

@keyframes shiftRight {
  to {
    transform: translateX(20px);
    /* Move the text 20px to the right */
  }
}
</style>
