<template>
    <!-- HTML for navigation bar -->
    <nav class="nav_xyz">
        <ul class="ul_xyz">
            <!--<li class="li_xyz"><a class="nav_a_xyz" href="#">Board of Directors</a></li>-->
            <li class="li_xyz">
                <router-link @click="scrollToTop" class="nav_a_xyz" to="/GoverningBoard">CEDI Governing Board</router-link>
            </li>
            <!--
            <li class="li_xyz">
                <router-link @click="scrollToTop" class="nav_a_xyz" to="/AdvisoryBoard">Advisory Board</router-link>
            </li>
            -->
            <li class="li_xyz">
                <router-link @click="scrollToTop" class="nav_a_xyz" to="/AdvisoryBoard">Expert Committee Members</router-link>
            </li>
            <li class="li_xyz">
                <router-link @click="scrollToTop" class="nav_a_xyz" to="/AdminTeam">Team</router-link>
            </li>
            <!--- REMOVED
            <li class="li_xyz">
                <router-link @click="scrollToTop" class="nav_a_xyz" to="/AdminMentors">Mentors</router-link>
            </li>
            -->
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'AdminVerticalBar',
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style scoped>
/* CSS for navigation bar */
.nav_xyz {
    background-color: #333;
    height: 197px;
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    padding: 20px 0;
    margin: 20px;
    margin-top: 230px;
}

.ul_xyz {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.li_xyz {
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;
    border-bottom: 1px solid #555;
}

nav li:last-child {
    border-bottom: none;
}

.nav_a_xyz {
    display: block;
    padding: 10px 0;
    color: #fff;
    text-decoration: none;
}

nav a:hover {
    background-color: #555;
    color: white;
}

header {
    padding: 20px;
    text-align: center;
}

router-link {
    display: block;
    padding: 10px 0;
    color: #fff;
    text-decoration: none;
}
</style>