import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { createApp } from 'vue'
import App from './App.vue'

//import home from './components/Home.vue';
const home = () => import('./components/Home/Home.vue')
import navbar from './components/Essentials/NavBar.vue';
import header from './components/Essentials/TopHeader.vue';
//const navbar = () => import('./components/Essentials/NavBar.vue')
import copyrightFooter from './components/Essentials/CopyrightFooter.vue';
//const copyrightFooter = () => import('./components/Essentials/CopyrightFooter.vue')
//import test from './components/Essentials/test.vue'; //use to test
const test = () => import('./components/Essentials/test.vue')

import adminVerticalBar from './components/Administration/AdminVerticalBar.vue';
//const adminVerticalBar = () => import('./components/Administration/AdminVerticalBar.vue')
//import governingBoard from './components/Administration/GoverningBoard.vue';
const governingBoard = () => import('./components/Administration/GoverningBoard.vue')
//import advisoryBoard from './components/Administration/AdvisoryBoard.vue';
const advisoryBoard = () => import('./components/Administration/AdvisoryBoard.vue')
//import adminTeam from './components/Administration/AdminTeam.vue';
const adminTeam = () => import('./components/Administration/AdminTeam.vue')
//import adminMentors from './components/Administration/AdminMentors.vue';
const adminMentors = () => import('./components/Administration/AdminMentors.vue')

//import meity from './components/Sponsors/MeiTy.vue';
const meity = () => import('./components/Sponsors/MeiTy.vue')
//import sonata from './components/Sponsors/Sonata.vue';
const sonata = () => import('./components/Sponsors/Sonata.vue')
//import asiaFoundation from './components/Sponsors/Asia.vue';
const asiaFoundation = () => import('./components/Sponsors/Asia.vue')
//import tnsrlm from './components/Sponsors/TNSRLM.vue';
const tnsrlm = () => import('./components/Sponsors/TNSRLM.vue')
//import tiic from './components/Sponsors/TIIC.vue';
const tiic = () => import('./components/Sponsors/TIIC.vue')
//import ediiTamilNadu from './components/Sponsors/EdiiTamilNadu.vue';
const ediiTamilNadu = () => import('./components/Sponsors/EdiiTamilNadu.vue')
//import ediiAhmedabad from './components/Sponsors/EdiiAhmedabad.vue';
const ediiAhmedabad = () => import('./components/Sponsors/EdiiAhmedabad.vue')
const twi = () => import('./components/Sponsors/TWI.vue')
const siemens = () => import('./components/Sponsors/SIEMENS.vue')


//import acet from './components/MoU/Acet.vue';
const startupCompendium = () => import('./components/MoU/StartupCompendium.vue')
const acet = () => import('./components/MoU/Acet.vue')
//import ccw from './components/MoU/Ccw.vue';
const ccw = () => import('./components/MoU/Ccw.vue')
//import cit from './components/MoU/Cit.vue';
const cit = () => import('./components/MoU/Cit.vue')
//import kcet from './components/MoU/Kcet.vue';
const kcet = () => import('./components/MoU/Kcet.vue')
//import kricNec from './components/MoU/KricNec.vue';
const kricNec = () => import('./components/MoU/KricNec.vue')

const nehruMemorial = () => import('./components/MoU/Nehru Memorial.vue')
const imayam = () => import('./components/MoU/Imayam.vue')
const bonSecours = () => import('./components/MoU/Bon Secours.vue')

//import incubationServices from './components/Facilities/IncubationServices.vue';
const incubationServices = () => import('./components/Facilities/IncubationServices.vue')
//import labs from './components/Facilities/Labs.vue';
const labs = () => import('./components/Facilities/Labs.vue')
//import contributionEcosystem from './components/Facilities/ContributionEcosystem.vue';
const contributionEcosystem = () => import('./components/Facilities/ContributionEcosystem.vue')

//import pastEvents from './components/Events/PastEvents.vue';
const pastEvents = () => import('./components/Events/PastEvents.vue')
//import workshops from './components/Events/Workshops.vue';
const workshops = () => import('./components/Events/Workshops.vue')

//import application from './components/Application/Application.vue';
const application = () => import('./components/Application/Application.vue')
//import applicationInfo from './components/Application/ApplicationInfo.vue';
const applicationInfo = () => import('./components/Application/ApplicationInfo.vue')
//import prelimApplication from './components/Application/PreliminaryApplication.vue';
const prelimApplication = () => import('./components/Application/PreliminaryApplication.vue')

const report = () => import('./components/Report/Report.vue')

//import donate from './components/Donate.vue';
const donate = () => import('./components/Donate.vue')

//import contact from './components/Contact.vue';
const contact = () => import('./components/Contact.vue')

import VueNumber from 'vue-number-animation'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue-cookies'

import img8 from '@/assets/background/cool_hd_wlp.png';

import { createPinia } from 'pinia';
const pinia = createPinia();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: home },
    { path: '/GoverningBoard', component: governingBoard },
    { path: '/AdvisoryBoard', component: advisoryBoard },
    { path: '/AdminTeam', component: adminTeam },
    { path: '/AdminMentors', component: adminMentors },

    { path: '/IncubationServices', component: incubationServices },
    { path: '/Labs', component: labs },
    { path: '/ContributionEcosystem', component: contributionEcosystem },

    { path: '/MeiTy', component: meity },
    { path: '/SONATA', component: sonata },
    { path: '/AsiaFoundation', component: asiaFoundation },
    { path: '/TNSRLM', component: tnsrlm },
    { path: '/TIIC', component: tiic },
    { path: '/EdiiTamilNadu', component: ediiTamilNadu },
    { path: '/EdiiAhmedabad', component: ediiAhmedabad },
    { path: '/TWI', component: twi },
    { path: '/SIEMENS', component: siemens },

    { path: '/StartupCompendium', component: startupCompendium },
    { path: '/ACET', component: acet },
    { path: '/CCW', component: ccw },
    { path: '/CIT', component: cit },
    { path: '/KCET', component: kcet },
    { path: '/KRIC-NEC', component: kricNec },
    { path: '/NehruMemorial', component: nehruMemorial },
    { path: '/Imayam', component: imayam },
    { path: '/BonSecours', component: bonSecours },

    { path: '/PastEvents', component: pastEvents },
    { path: '/Workshops', component: workshops },

    { path: '/Application', component: application },
    { path: '/ApplicationInfo', component: applicationInfo },
    { path: '/NewApplication', component: prelimApplication },

    { path: '/Report', component: report },

    { path: '/Donate', component: donate },

    { path: '/Contact', component: contact },

    { path: '/test', component: test } //use to test
  ]
});

const app = createApp(App);

app.config.globalProperties.$bg_img = img8

app.component('top-header', header);
app.component('nav-bar', navbar);
app.component('copyright-footer', copyrightFooter);
app.component('admin-vertical-bar', adminVerticalBar);
app.component('application-info', applicationInfo);
app.component('application-login-register', application);

app.component('QuillEditor', QuillEditor)
app.use(VueSweetalert2);
app.use(VueNumber)
app.use(router)
app.use(pinia)
app.use(VueCookies)
app.mount('#app');
